import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="129" height="8" viewBox="0 0 129 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.9831 8C21.3161 7.25735 1.78755 8.08646 0.368103 7.86265C-0.790746 7.56254 1.06119 2.27751 2.13687 1.49925C2.51391 1.21949 2.95195 1.17881 3.95554 1.30597C5.54688 1.54505 26.1234 1.07198 26.1234 1.07198C26.3951 1.23475 27.2101 0.685396 27.3599 0.883775C27.8312 1.34666 61.1827 0.715913 64.0272 0.69048C65.7516 0.67522 64.3876 0.99059 66.4169 0.863424C70.7418 0.609092 80.1679 0.853252 83.4448 0.563314C86.0675 0.324241 86.633 0.314075 89.1503 0.507368C91.7231 0.680314 105.402 0.431066 106.966 0.0597408C107.348 -0.0419921 107.642 -0.00638251 107.642 0.11061C107.642 0.136044 117.284 0.222513 117.329 0.314073C117.401 0.431066 117.695 0.487015 117.966 0.405629C118.471 0.283549 128.734 -0.15899 128.895 0.181815C129.4 1.15845 127.986 5.31932 126.794 6.33157C125.574 7.41502 118.876 6.19932 113.492 6.85041L88.424 6.91654L83.0511 7.32347C82.4357 6.97249 80.0681 7.85756 78.6375 7.22682C78.0332 6.97757 73.7637 6.94196 72.8211 7.20647C72.0005 7.40994 50.1598 6.89618 47.9197 7.60323C43.9275 7.01318 27.7369 7.85757 23.9831 8Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="164" height="8" viewBox="0 0 164 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.4902 8C27.0995 7.25735 2.27255 8.08646 0.467975 7.86265C-1.00529 7.56254 1.34912 2.27751 2.71664 1.49925C3.19598 1.21949 3.75286 1.17881 5.02875 1.30597C7.05185 1.54505 33.2111 1.07198 33.2111 1.07198C33.5565 1.23475 34.5927 0.685396 34.7831 0.883775C35.3822 1.34666 77.7827 0.715913 81.3989 0.69048C83.5911 0.67522 81.8571 0.99059 84.437 0.863424C89.9354 0.609092 101.919 0.853252 106.085 0.563314C109.419 0.324241 110.138 0.314075 113.338 0.507368C116.609 0.680314 133.999 0.431066 135.987 0.0597408C136.474 -0.0419921 136.847 -0.00638251 136.847 0.11061C136.847 0.136044 149.106 0.222513 149.162 0.314073C149.254 0.431066 149.627 0.487015 149.973 0.405629C150.614 0.283549 163.662 -0.15899 163.866 0.181815C164.508 1.15845 162.71 5.31932 161.195 6.33157C159.644 7.41502 151.129 6.19932 144.284 6.85041L112.415 6.91654L105.584 7.32347C104.802 6.97249 101.792 7.85756 99.9733 7.22682C99.2049 6.97757 93.7771 6.94196 92.5788 7.20647C91.5355 7.40994 63.769 6.89618 60.9212 7.60323C55.8458 7.01318 35.2624 7.85757 30.4902 8Z" fill="#FFA700" />
    </svg>
  </span>
)