import React from "react"
import imgQuestion from "@components/pageFunctionalities/pageWhatsappPrecio/question/image/question.png"
import { TitleUnderlineOne, TitleUnderlineTwo } from "./titleUnderline"

const Question = () => {
    return (
        <section className="container-question-whatsapp-precio">
            <div className="container-question-whatsapp-precio-title">
            ¿Cómo se <TitleUnderlineOne underline="generan" /> <TitleUnderlineTwo underline="los costos" />     de la API de WhatsApp Business?</div>
            <section className="container-question-whatsapp-precio-info">
                <p className="container-question-whatsapp-precio-info-text">El precio de la API de WhatsApp Business se determina por las conversaciones. Además, estos tienen ligeras variaciones según el país en el que te encuentres. En cuanto a los mensajes, el cobro se hace por categorías como:</p>
                <img
                    src={imgQuestion}
                    alt=""
                    className="container-question-whatsapp-precio-info-image"
                />
            </section>
        </section>
    )
}

export default Question