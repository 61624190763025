
import React from "react"
import qrImage from "@components/pageFunctionalities/pageWhatsappPrecio/escaner/image/qrImg.png"
import icon from "@components/pageFunctionalities/pageWhatsappPrecio/escaner/image/icon.png"
import { Link } from "gatsby"
import { TitleUnderlineOne, TitleUnderlineTwo } from "./titleUnderline"


const Escaner = () => {
    return (
        <section className="container-escaner-page-whatsapp-precio">
            <h2 className="container-escaner-page-whatsapp-precio-title">
            <TitleUnderlineOne underline="¿Cómo se ve una" /> <TitleUnderlineTwo underline="cuenta" /> de WhatsApp con la API?
            </h2>
            <section className="container-escaner-pages-relative">
                <div>
                    <img src={icon} alt="" className="container-escaner-pages-relative-icon" />
                    <p className="container-escaner-pages-relative-text">Escanear código QR</p>
                    <img src={qrImage} alt="" className="container-escaner-pages-relative-img" />
                </div>

                <p className="container-escaner-pages-relative-description">
                    Conoce en tiempo real cómo funciona un mensaje interactivo de WhatsApp.
                    Escanea este código QR y empieza a interactuar. En caso tengas
                    problemas, puedes hacerlo ingresado a este{" "}
                    <Link
                        className="link"
                        href="https://wa.me/+51953847150"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        enlace
                    </Link>
                    .
                </p>
            </section>
        </section>
    )
}

export default Escaner
