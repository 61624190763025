import React from "react"
import { Link } from "gatsby"

const Start = ({ data, location }) => {
  return (
    <section className="container-intro-whatsapp-precio" >      
      <div className="vector1-intro-precios-nuevos">
        <svg
          width="318"
          height="298"
          viewBox="0 0 318 298"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.1738 228.704C78.101 295.719 194.153 323.925 267.659 268.713C309.206 231.705 324.386 166.491 315.598 112.279C266.061 -75.3623 -158.796 -21.9506 21.1738 228.704Z"
            fill="#CCF5D9"
          />
        </svg>
      </div>
      <br /> 
      <div className="container-intro-start-whatsapp">
        <section>
          <img loading="lazy" src={data.logo} alt="price" />
          <div className="container-intro-whatsapp-precio-title">{data.title}</div>
          <div className="text-intro-whatsapp">{data.textbody}</div>
            <Link
              className="container-intro-whatsapp-precio-button"
              target="_blank"
              rel="noopener noreferrer"
              href={`/whatsapp-business-api/contacto-whatsapp-api/${location.search}`}
            >
              {data.textbutton}
            </Link>
        </section>
      </div>
      <div className="vector2-intro-precios-nuevos">
        <svg
          width="358"
          height="464"
          viewBox="0 0 358 464"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M614.722 308.138C734.633 164.062 623.184 -48.0417 433.884 10.4178C355.028 49.4656 283.308 95.6829 189.726 111.435C-3.13668 110.324 -73.4968 324.921 99.1228 423.025C154.998 454.778 231.805 465.426 300.342 463.089C484.536 430.768 519.104 423.025 614.722 308.138Z"
            fill="#CCF5D9"
          />
        </svg>
      </div>
      <br />
    </section>
  )
}

export default Start
